import "./styles.scss"

import React from "react"

import DiagnosticsSingle from "../DiagnosticsSingle"

const DiagnosticsList = ({ offer }) => {
  return (
    <section className="diagnostics-list">
      <div className="container">
        <div className="diagnostics-list__items">
          <div className="row">
            {offer.map(item => (
              <DiagnosticsSingle key={item.id} item={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default DiagnosticsList
