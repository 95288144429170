import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import SliderArrow from "components/Icons/SliderArrow/slider-arrow"

const DiagnosticsSingle = ({ item }) => {
  const { thumbnail, title } = item.acfDiagnostics

  return (
    <div className="col-lg-4 col-sm-6 col-12">
      <Link to={`/diagnostyka/${item.slug}/`}>
        <div className="diagnostics-single">
          <div className="diagnostics-single__img">
            <img src={thumbnail?.sourceUrl} alt={thumbnail?.altText} />
          </div>
          <div className="diagnostics-single__content">
            <p className="diagnostics-single__title">{title}</p>
            <div className="diagnostics-single__cta">
              <span className="diagnostics-single__cta-text">
                Dowiedz się więcej
              </span>
              <div className="diagnostics-single__cta-arrow">
                <SliderArrow />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default DiagnosticsSingle
