import "./styles.scss"

import React from "react"

const DiagnosticsInfo = () => {
  return (
    <section className="diagnostics-info">
      <div className="container">
        <div className="d-flex flex-column-reverse flex-lg-row">
          <div className="col-lg-6 col-12">
            <p className="diagnostics-info__text">
              Diagnostyka podologiczna to proces badania stóp, który ma na celu
              dokładną diagnozę i ocenę stanu zdrowia stóp pacjenta. W gabinecie
              podologicznym można skorzystać z różnorodnych badań i testów,
              które pomogą podologowi dokładnie ocenić stan zdrowia stóp
              pacjenta i dobrać odpowiednie leczenie.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <div className="diagnostics-info__img">
              <img
                src={require("assets/images/offer/diagnostyka.jpg").default}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DiagnosticsInfo
