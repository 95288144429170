import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import { DiagnosticsInfo, DiagnosticsList } from "page_components/diagnostics"

const Diagnostics = ({ data }) => {
  const breadcrumbs_data = [
    {
      name: "Diagnostyka",
      href: "/diagnostyka/",
    },
  ]

  return (
    <Layout
      seo={{
        title: "Diagnostyka",
        description:
          "Pracujemy na najwyższej jakości sprzętach, urządzeniach i preparatach. Szczególną uwagę poświęcamy osobom chorym na: cukrzycę, RZS, hemofilię oraz przewlekłe choroby.",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Diagnostyka" />
      <DiagnosticsInfo />
      <DiagnosticsList offer={data?.allWpDiagnosis?.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpDiagnosis {
      nodes {
        uri
        title
        slug
        id
        acfDiagnostics {
          title
          thumbnail {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`

export default Diagnostics
